import { useEffect } from "react";
import { RecommendationType, HobbiesElementType } from "types/DataTypes";
import styled from "styled-components";
import kakao_logo from "@assets/images/kakao.png";
import save_logo from "@assets/images/result_save.png";
import HeaderTextBlock from "blocks/HeaderTextBlock";
import html2canvas from "html2canvas";

type Props = {
    user_id: string | null;
    RecomData: RecommendationType | undefined;
    RecomHobbies: HobbiesElementType[] | undefined;
    ShareModalOff: () => void;
};

const ShareModal = (props: Props) => {
    const today = new Date();

    useEffect(() => {
        const script = document.createElement("script");
        // script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        // script.async = true;
        script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.3.0/kakao.min.js";
        script.integrity =
            "sha384-70k0rrouSYPWJt7q9rSTKpiTfX6USlMYjZUtr1Du+9o4cGvhPAWxngdtVZDdErlh";
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onSaveAs = (url: string, filename: string) => {
        let link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
    };
    const SaveButtonHandler = () => {
        html2canvas(document.getElementById("capture_div")!, {
            useCORS: true,
        }).then((canvas) => {
            onSaveAs(
                canvas.toDataURL("image/png"),
                `screenshot-${today.getHours()}${today.getMinutes()}${today.getSeconds()}.png`
            );
        });
    };

    const KakaoShareHandler = (id: string) => {
        const SHARE_URI = `${process.env.REACT_APP_PUBLIC_CLIENT}share?id=${id}`;
        if (window.Kakao) {
            const kakao = window.Kakao;
            if (!kakao.isInitialized()) {
                kakao.init(process.env.REACT_APP_KAKAO_SHARE_KEY);
            }

            kakao.Share.sendDefault({
                objectType: "feed",
                content: {
                    title: "홀랑에 홀랑 빠져봐!",
                    description:
                        "자신에게 꼭 맞는 자기개발 콘텐츠을 추천받아보세요!",
                    imageUrl:
                        "https://des7614zs4r60.cloudfront.net/common/main-character.png",
                    link: {
                        mobileWebUrl: SHARE_URI,
                        webUrl: SHARE_URI,
                    },
                },
                buttons: [
                    {
                        title: "홀랑에 홀랑 빠져봐!",
                        link: {
                            mobileWebUrl: SHARE_URI,
                            webUrl: SHARE_URI,
                        },
                    },
                ],
            });
        }
    };

    return (
        <ShareWrapper>
            <ShareHeaderDiv>
                <HeaderTextBlock
                    title="결과 공유하기"
                    Handler={props.ShareModalOff}
                />
            </ShareHeaderDiv>
            <ShareGreyDiv id="capture_div">
                <ShareCenterWrapper>
                    <ShareCenterIMG
                        src={props.RecomData!.hobbyType.imageUrl}
                        alt=""
                    />
                    <ResultText>
                        {props.RecomData!.user.name}님의 추천 컨텐츠예요!
                    </ResultText>
                    <ShareRecomDiv>
                        {props.RecomHobbies!.map((hobby, idx) => (
                            <RecomCard key={idx}>
                                <RecomCircle>
                                    <RecomIMG src={hobby.imageUrl} alt="" />
                                </RecomCircle>
                                <RecomText>{hobby.shortName}</RecomText>
                            </RecomCard>
                        ))}
                    </ShareRecomDiv>
                    <ShareRecomDescDiv>
                        <RecomDescTextDiv>
                            {props.RecomHobbies!.map((hobby, idx) => (
                                <div key={idx + 3}>
                                    <RecomDescText>
                                        {hobby.summary}
                                    </RecomDescText>
                                    {/* <br /> */}
                                </div>
                            ))}
                        </RecomDescTextDiv>
                    </ShareRecomDescDiv>
                </ShareCenterWrapper>
            </ShareGreyDiv>
            <ShareButtonsDiv>
                <ShareSaveImg
                    src={save_logo}
                    alt=""
                    onClick={SaveButtonHandler}
                />
                <KakaoTalkButton
                    onClick={() => KakaoShareHandler(props.user_id!)}
                >
                    <KakaoImg src={kakao_logo} alt="" />
                    카카오톡으로 결과 공유하기
                </KakaoTalkButton>
            </ShareButtonsDiv>
        </ShareWrapper>
    );
};

export default ShareModal;

const ShareHeaderDiv = styled.div`
    height: 11.374vh;
    width: 390rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const ShareGreyDiv = styled.div`
    width: 390rem;
    height: 80vh;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ShareButtonsDiv = styled.div`
    width: 337rem;
    padding-left: 26.5rem;
    padding-right: 26.5rem;
    // height: 13.033vh;
    height: 8.625vh;
    display: flex;
    margin: 0 auto;
    background: #f8f8f8;
`;

const ShareCenterWrapper = styled.div`
  display: flex
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShareCenterIMG = styled.img`
    margin: 0 auto;
    width: 150rem;
    height: 150rem;
    margin-bottom: 13.5rem;
`;

const ShareSaveImg = styled.img`
    width: 73rem;
    height: 51rem;
    cursor: pointer;
`;

const ShareRecomDiv = styled.div`
    margin: 0 auto;
    margin-top: 13.5rem;
    margin-bottom: 12rem;
    width: 266rem;
    height: 95rem;
    display: flex;
    justify-content: space-between;
`;

const ShareRecomDescDiv = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 330rem;
    height: 224rem;
    background: #ffffff;
    border-radius: 10rem;
`;

const ShareWrapper = styled.div`
    width: 390rem;
    height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const ResultText = styled.div`
    margin: 0 auto;
    width: 390rem;
    height: 30rem;
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 17rem;
    line-height: 30rem;
`;

const RecomCard = styled.div`
    min-width: 62rem;
    height: 95rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RecomIMG = styled.img`
    width: 40rem;
    height: 40rem;
`;

const RecomText = styled.div`
    height: 25rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 13rem;
    line-height: 25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3e404c;
`;

const RecomCircle = styled.div`
    width: 62rem;
    height: 62rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.05);
    border-radius: 31rem;
    margin-bottom: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1.5rem solid #49ff66;
`;

const RecomDescTextDiv = styled.ul`
    width: 263rem;
    /* height: 216rem; */
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 14rem;
    line-height: 24rem;
    color: #525463;
    text-align: left;
    margin-left: 17.75rem;
    padding-left: 0;
    word-break: keep-all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const RecomDescText = styled.li`
    list-style-position: outside;
    margin: 10rem 0;
    /* text-indent: -19rem; */
`;

const KakaoTalkButton = styled.button`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 330rem;
    height: 51rem;
    background: #fee500;
    border-radius: 25rem;
    border: 0px;
    display: flex;
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 16rem;
    line-height: 19rem;
    text-align: center;
    color: black;
    cursor: pointer;
`;

const KakaoImg = styled.img`
    width: 20rem;
    height: 19rem;
    margin-right: 9rem;
`;
