import styled, { css, keyframes } from "styled-components";
interface ButtonType {
  buttoncolor: string;
  vibrate: number;
}
const vibrate_hori = keyframes`
0%{
  transform: translate(0%,0);
}
25%{
  transform: translate(-0.5%,0);
}
50%{
  transform: translate(0%,0);
}
75%{
  transform: translate(0.5%,0);
}
100%{
  transform: translate(0%,0);
}
`;

const StyledButton = styled.button<ButtonType>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  width: 330rem;
  height: 51rem;

  background: ${(props) =>
    props.buttoncolor === "green" ? "#a7ffb2" : "#f1f1f1"};
  border-radius: 25rem;
  margin: 0 auto;

  font-family: "Pretendard-SemiBold";
  font-size: 16rem;
  line-height: 19rem;

  text-align: center;

  color: #000000;

  cursor: ${(props) => props.buttoncolor === "green" && "pointer"};

  ${(props) =>
    props.vibrate &&
    css`
      animation: ${vibrate_hori} 0.1s infinite;
    `}
`;

export default StyledButton;
