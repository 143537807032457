import styled from "styled-components";
import axios from "axios";
import HeaderTextBlock from "blocks/HeaderTextBlock";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RankingResponseType } from "types/DataTypes";
import LoadingPage from "./LodingPage";

const RankingPage = () => {
    const navigate = useNavigate();
    const [isLoaded, setLoaded] = useState(false);
    const [rankingResponse, setData] = useState<RankingResponseType | null>(
        null
    );
    const RankingData = rankingResponse?.data.hobbies;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_ENDPOINT +
                        "hobbies?page=0&size=20&sort=recommendCount"
                );
                setData(response.data);
                setLoaded(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    if (!isLoaded) {
        <LoadingPage stage="ranking" />;
    }

    return (
        <Wrapper>
            <HeaderTextBlock
                title="자기 개발 추천 순위"
                Handler={() => navigate("/")}
            />
            {/* <HeightBox height="44rem;" /> */}
            <CenterDiv>
                {RankingData?.map((item, idx) => (
                    <ContentWrapper key={idx}>
                        <ContentWhiteCircle>
                            <ContentImg src={item.imageUrl} alt="" />
                        </ContentWhiteCircle>
                        <ContentHolder>
                            <Text1>{idx + 1}위</Text1>
                            <Text2>{item.shortName}</Text2>
                            <Text3>{item.recommendCount}명</Text3>
                        </ContentHolder>
                    </ContentWrapper>
                ))}
            </CenterDiv>
        </Wrapper>
    );
};

export default RankingPage;

const Wrapper = styled.div`
    height: 100vh;
    width: 390rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    margin: 0 auto;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const CenterDiv = styled.div`
    height: 81.63vh;
    width: 390rem;
    overflow-y: scroll;
    background: #ffffff;
    &::-webkit-scrollbar {
        display: none;
    }
    padding-top: 20rem;
`;

const ContentWrapper = styled.div`
    width: 330rem;
    height: 51rem;
    margin: 0 auto;
    margin-bottom: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
`;

const ContentWhiteCircle = styled.div`
    width: 45rem;
    height: 45rem;
    background: #ffffff;
    border: 1.5rem solid #ffffff;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContentImg = styled.img`
    width: 28.52rem;
    height: 28.52rem;
`;

const ContentHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 276rem;
    height: 51rem;
    background: #d9ffde;
    border-radius: 25rem;
`;

const Text1 = styled.span`
    width: 44rem;
    height: 30rem;

    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 400; */
    font-size: 15rem;
    line-height: 30rem;
    text-align: center;
    color: #3e404c;
`;

const Text2 = styled.span`
    width: 128rem;
    height: 30rem;

    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 400; */
    font-size: 15rem;
    line-height: 30rem;
    text-align: center;
    color: #3e404c;
`;

const Text3 = styled.span`
    width: 59rem;
    height: 30rem;

    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 400; */
    font-size: 15rem;
    line-height: 30rem;
    text-align: center;
    color: #3e404c;
`;
