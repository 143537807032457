import { HobbiesElementType } from "types/DataTypes";
import { styled } from "styled-components";
import * as C from "@styles/ModalCommonComponent";
import StyledButton from "@styles/StyledButton";
import backimg from "@assets/images/back.png";
import top_logo from "@assets/images/week52_logo.png";

type Props = {
    RecomHobbies: HobbiesElementType[];
    RecomNum: number;
    RecomModalOff: () => void;
    RecomSelectChangeHandler: (num: number) => void;
};

const RecomModal = (props: Props) => {
    return (
        <C.ResultWrapper>
            <TopDiv />
            <HeaderDiv>
                <BackButton onClick={props.RecomModalOff}>
                    <BackImg src={backimg} alt="" />
                </BackButton>
                <TopLogo src={top_logo} alt="" />
                <HeaderPadding />
            </HeaderDiv>
            <CenterIMGdiv>
                <RecomModalCenterIMG
                    src={props.RecomHobbies[props.RecomNum].imageUrl}
                    alt=""
                />
            </CenterIMGdiv>
            <C.RecomSelectDiv>
                {props.RecomHobbies.map((hobby, idx) => (
                    <C.SelectBox
                        key={idx}
                        isselected={props.RecomNum === idx ? 1 : 0}
                        onClick={() => props.RecomSelectChangeHandler(idx)}
                    >
                        {hobby.shortName}
                    </C.SelectBox>
                ))}
            </C.RecomSelectDiv>
            <C.RecomSelectDescDiv>
                <C.HeightBox height={28} />
                <RecomSelectDescWrapper>
                    <C.DescTitle>
                        {props.RecomHobbies[props.RecomNum].originalName}
                    </C.DescTitle>
                    <C.HeightBox height={10} />
                    <C.DescText>
                        {props.RecomHobbies[props.RecomNum].description}
                    </C.DescText>
                </RecomSelectDescWrapper>
                <C.HeightBox height={16} />
                <StyledButton
                    buttoncolor="green"
                    vibrate={0}
                    onClick={() => {
                        window.location.href =
                            props.RecomHobbies[props.RecomNum].contentUrl;
                    }}
                >
                    더 알아보기
                </StyledButton>
            </C.RecomSelectDescDiv>
        </C.ResultWrapper>
    );
};

export default RecomModal;

const RecomSelectDescWrapper = styled.div`
    margin: 0 auto;
    width: 330rem;
    background: #ffffff;
    border-radius: 10rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    min-height: 227rem;
`;

const TopDiv = styled.div`
    height: 48rem;
    width: 100%;
    flex-grow: 48;
`;

const HeaderDiv = styled.div`
    height: 50rem;
    width: 100%;
    flex-grow: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BackImg = styled.img`
    width: 28.43rem;
    height: 34.11rem;
    cursor: pointer;
`;

const BackButton = styled.button`
    width: 28.43rem;
    height: 34.11rem;
    border: 0px;
    background: white;
`;

const HeaderPadding = styled.div`
    width: 28.43rem;
    height: 34.11rem;
    margin-left: 5rem;
`;

const TopLogo = styled.img`
    width: 107.231rem;
    flex-shrink: 0;
`;

const RecomModalCenterIMG = styled.img`
    margin: 0 auto;
    width: 230rem;
    height: 230rem;
`;

const CenterIMGdiv = styled.div`
    height: 38.15vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;
