import { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import center_logo from "@assets/images/question_img.png";
import { useNavigate } from "react-router-dom";
import { QuestionDataType, QuestionType } from "types/DataTypes";
import { ProgressType } from "types/Interfaces";
import HeaderTextBlock from "blocks/HeaderTextBlock";
import axios from "axios";
import GoogleAd from "@components/GoogleAd";

type AnswerPostType = {
    questionNumber: number;
    answerNumber: number;
};

const Question = () => {
    const [isLoaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [questionNumber, setNumber] = useState(1);
    const [questionResponse, setData] = useState<QuestionDataType | null>(null);
    const [userAnswer, setAnswer] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [isPushed, setPushed] = useState(false);
    const [progressNum, setProgressNum] = useState(1.0);
    const [isFirstButtonPushed, setFirstButtonPushed] = useState(false);
    const [isSecondButtonPushed, setSecondButtonPushed] = useState(false);

    const QuestionData: QuestionDataType | null = questionResponse;
    const QuestionList: QuestionType[] | undefined =
        QuestionData?.data.test.questions;

    const BackHandler = () => {
        navigate("/register");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_ENDPOINT + "tests?version=1"
                );
                setData(response.data);
                setLoaded(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const NextHandler = (choice: number) => {
        if (choice === 1) {
            setFirstButtonPushed(true);
            setTimeout(() => setFirstButtonPushed(false), 500);
        }
        if (choice === 2) {
            setSecondButtonPushed(true);
            setTimeout(() => setSecondButtonPushed(false), 500);
        }
        setTimeout(() => setPushed(true), 500);
        setTimeout(() => setPushed(false), 2 * 500);

        setTimeout(() => setProgressNum(progressNum), 500);
        for (let i = 0; i <= 1; i += 0.01) {
            setTimeout(() => setProgressNum(progressNum + i), 500 * i);
        }

        let curAnswer = userAnswer;
        curAnswer[QuestionList![questionNumber - 1].number - 1] = choice;

        setAnswer(curAnswer);
        if (questionNumber === 12) {
            let testResponseDetails: AnswerPostType[] = [];
            userAnswer.map((answer, index) =>
                testResponseDetails.push({
                    questionNumber: index + 1,
                    answerNumber: answer,
                })
            );
            const AnswerPostBody = {
                user: {
                    name: localStorage.getItem("username")!,
                },
                testResponseDetails: testResponseDetails,
            };
            axios({
                method: "post",
                url: process.env.REACT_APP_API_ENDPOINT + "test-responses",
                headers: {
                    "Content-Type": "application/json",
                },
                data: AnswerPostBody,
            }).then((response) => {
                const user_id = response.data.data.recommendation.id;
                setTimeout(() => navigate(`/result?id=${user_id}`), 1000);
            });
        } else {
            setTimeout(() => setNumber(questionNumber + 1), 2 * 500);
            setTimeout(() => setProgressNum(questionNumber + 1), 500);
        }
    };

    if (!isLoaded) {
        return (
            <Wrapper>
                <HeaderTextBlock
                    title="홀랑 테스트하기"
                    Handler={BackHandler}
                />
                <UnderDiv />
                <ProgressDiv>
                    <ProgressBar questionnum={progressNum} />{" "}
                </ProgressDiv>
                <LodingPad />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <HeaderTextBlock title="홀랑 테스트하기" Handler={BackHandler} />
            <UnderDiv />
            <ProgressDiv>
                <ProgressBar questionnum={progressNum} />{" "}
            </ProgressDiv>
            <CenterDiv ispushed={isPushed.toString()} selected="0">
                <CenterImg src={center_logo} alt="" />
                <QustionNumText>Q. {questionNumber}</QustionNumText>
            </CenterDiv>
            <TextDiv ispushed={isPushed.toString()} selected="0">
                {QuestionList![questionNumber - 1].content.replace(
                    "000",
                    localStorage.getItem("username")!
                )}
            </TextDiv>
            <ButtonDiv>
                <ChoiceButton
                    ispushed={isPushed.toString()}
                    onClick={() => NextHandler(1)}
                    selected={isFirstButtonPushed.toString()}
                >
                    {QuestionList![questionNumber - 1].answers[0].content}
                </ChoiceButton>
                <SizedBox />
                <ChoiceButton
                    ispushed={isPushed.toString()}
                    onClick={() => NextHandler(2)}
                    selected={isSecondButtonPushed.toString()}
                >
                    {QuestionList![questionNumber - 1].answers[1].content}
                </ChoiceButton>
            </ButtonDiv>
            <FooterDiv>
                <AdWrapper>
                    {questionNumber % 3 === 2 && <GoogleAd />}
                </AdWrapper>
            </FooterDiv>
        </Wrapper>
    );
};

export default Question;

type animationType = {
    ispushed: string;
    selected: string;
};

const move = keyframes`
0%{
  transform: translate(0%,0);
}
30%{
  transform: translate(5%,0);
}
100%{
  transform: translate(-150%,0);
}
`;

const firstmove = keyframes`
0%{
  transform: translate(150%,0);
}
70%{
  transform: translate(-8%,0);
}
90%{
  transform: translate(4%,0);
}
100%{
  transform: translate(0%,0);
}
`;

const Wrapper = styled.div`
    width: 390rem;
    height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    clip: rect(auto, auto, auto, auto);
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const UnderDiv = styled.div`
    height: 18rem;
    width: 100%;
    flex-grow: 18;
`;

const ProgressDiv = styled.div`
    height: 12rem;
    width: 100%;
    flex-grow: 12;
`;

const CenterDiv = styled.div<animationType>`
    height: 320rem;
    width: 100%;
    flex-grow: 320;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    ${css`
        animation: ${firstmove} 0.5s 1;
    `}

    ${(props) =>
        props.ispushed === "true" &&
        css`
            animation: ${move} 0.5s 1;
        `}
`;

const TextDiv = styled.div<animationType>`
    height: 84rem;
    width: 100%;
    flex-grow: 84;
    font-family: "Pretendard-Medium";
    font-size: 17rem;
    line-height: 28rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    white-space: pre-wrap;

    ${css`
        animation: ${firstmove} 0.5s 1;
    `}

    ${(props) =>
        props.ispushed === "true" &&
        css`
            animation: ${move} 0.5s 1;
        `}
`;

const ButtonDiv = styled.div`
    height: 214rem;
    width: 100%;
    flex-grow: 214;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FooterDiv = styled.div`
    height: 98rem;
    width: 100%;
    flex-grow: 98;
    display: flex;
    justify-content: center;
`;

const CenterImg = styled.img`
    width: 140rem;
    height: 140rem;
    margin-top: 93rem;
    // margin-bottom: 32rem;
`;

const QustionNumText = styled.span`
    margin-top: 10rem;
    height: 8.29vh;
    width: 390rem;
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 20rem;
    line-height: 15rem;
    color: #525463;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProgressBar = styled.div<ProgressType>`
    width: 290rem;
    height: 12rem;
    background: linear-gradient(
        90deg,
        #a7ffb2 ${(props) => (props.questionnum / 12) * 100}%,
        #f1f1f1 ${(props) => (props.questionnum / 12) * 100}%
    );
    border-radius: 10rem;
    margin: 0 auto;
`;

const pushed = keyframes`
0%{
  transform: translate(0%,0);
}
25%{
  transform: translate(0, -5%);
}
50%{
  transform: translate(0,20%);
}
75%{
  transform: translate(0,-5%);
}
100%{
  transform: translate(0%,0);
}
`;

const ChoiceButton = styled.button<animationType>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330rem;
    height: 51rem;
    background: #d9ffde;
    border: 1rem solid #98ffa7;
    border-radius: 25rem;

    font-family: "Pretendard-Medium";
    font-size: 16rem;
    line-height: 19rem;
    text-align: center;
    color: #000000;
    cursor: pointer;

    ${css`
        animation: ${firstmove} 0.5s 1;
    `}

    ${(props) =>
        props.selected === "true" &&
        css`
            animation: ${pushed} 0.5s 1;
        `}
    
  ${(props) =>
        props.ispushed === "true" &&
        css`
            animation: ${move} 0.5s 1;
        `}
`;

const SizedBox = styled.div`
    height: 20rem;
`;

const LodingPad = styled.div`
    height: 84.83vh;
`;

const AdWrapper = styled.div`
    width: 300px;
    height: 50px; */
`;
