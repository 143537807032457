import React, { useEffect, useState } from "react";
import styled from "styled-components";
import center_logo from "@assets/images/register_img.png";
import { useNavigate } from "react-router-dom";
import HeaderLogoBlock from "blocks/HeaderLogoBlock";
import StyledButton from "@styles/StyledButton";

const Register = () => {
    const navigate = useNavigate();
    const [inputText, setInput] = useState(localStorage.getItem("username")!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const korRule = /^[가-힣]{1,5}$/;
    const [val, setVal] = useState(korRule.test(inputText));
    const [vibrateState, setVibrate] = useState(0);

    useEffect(() => {
        korRule.test(inputText) ? setVal(true) : setVal(false);
    }, [inputText, korRule]);

    const ButtonHandler = () => {
        if (inputText.length === 0) {
            setVibrate(1);
            setTimeout(() => setVibrate(0), 200);
        } else {
            if (val) {
                localStorage.setItem("username", inputText);
                navigate("/question");
            } else {
                setVibrate(1);
                setTimeout(() => setVibrate(0), 200);
            }
        }
    };

    const ChangeHandler = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setInput(e.target.value);
        korRule.test(inputText) ? setVal(true) : setVal(false);
    };

    const EnterHandler = (e: { key: string }) => {
        if (e.key === "Enter") {
            ButtonHandler();
        }
    };

    return (
        <Wrapper>
            <HeaderLogoBlock />
            <UnderLogo />
            <TextDiv>
                <FirstLine>
                    <Green>홀랑</Green>에 빠질 준비 되었나요?
                </FirstLine>
                <SecondLine>
                    자신에게 꼭 맞는 자기개발 콘텐츠를 추천받아보세요!
                </SecondLine>
            </TextDiv>
            <CenterDiv>
                <CenterImg src={center_logo} alt="" />{" "}
            </CenterDiv>
            <InputDiv>
                <InputBox
                    onChange={ChangeHandler}
                    placeholder="닉네임을 한글로 입력해주세요"
                    onKeyPress={EnterHandler}
                    defaultValue={localStorage.getItem("username")!}
                ></InputBox>
                {!val && inputText.length !== 0 && (
                    <ValText>한글로 다섯 글자 이내까지 입력 가능해요.</ValText>
                )}
            </InputDiv>
            <ButtonDiv>
                <StyledButton
                    vibrate={vibrateState}
                    buttoncolor={val ? "green" : "grey"}
                    onClick={ButtonHandler}
                >
                    홀랑 테스트 하기
                </StyledButton>
            </ButtonDiv>
        </Wrapper>
    );
};

export default Register;

const Wrapper = styled.div`
    width: 390rem;
    // height: ${window.innerHeight}px;
    height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const UnderLogo = styled.div`
    height: 51rem;
    width: 100%;
    flex-grow: 51;
`;

const TextDiv = styled.div`
    height: 122rem;
    width: 100%;
    flex-grow: 132;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
`;
const FirstLine = styled.div`
    margin-top: 2rem;
    margin-left: 30rem;
    font-family: "Pretendard-Bold";
    font-size: 22rem;
    line-height: 26rem;
    text-align: center;

    color: #0e0e0e;
`;
const SecondLine = styled.div`
    margin-top: 12rem;
    margin-left: 30rem;
    font-family: "Pretendard-Medium";
    font-size: 15rem;
    line-height: 18rem;
    text-align: center;

    color: #3e404c;
`;
const Green = styled.span`
    color: #51d864;
`;

const CenterDiv = styled.div`
    height: 201rem;
    weight: 100%;
    flex-grow: 187;
`;
const CenterImg = styled.img`
    height: 187rem;
    width: 178rem;
`;
const InputDiv = styled.div`
    height: 258rem;
    weight: 100%;
    flex-grow: 258;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const InputBox = styled.input`
    margin: 0 auto;
    margin-top: 47rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    //   padding: 10rem 30rem;
    gap: 10rem;
    width: 330rem;
    height: 51rem;
    background: #f1f1f1;
    border-radius: 25rem;
    border: 0px;

    text-align: left;
    font-family: "Pretendard-Medium";
    font-size: 15rem;
    text-indent: 30rem;

    color: #858899;

    &:focus {
        outline: 0;
    }
`;

const ValText = styled.span`
    font-family: "Pretendard-Medium";
    font-size: 12rem;
    line-height: 16rem;

    color: #f84a4a;
    margin-left: 60rem;
    margin-top: 8rem;
`;

const ButtonDiv = styled.div`
    height: 110rem;
    weight: 100%;
    flex-grow: 160;
`;
