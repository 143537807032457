import { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import styled, { css, keyframes } from "styled-components";
import center_logo from "@assets/images/thankyou.png";
import StyledButton from "@styles/StyledButton";
import {
    FitElementType,
    HobbiesElementType,
    RecommendationType,
} from "types/DataTypes";
import LoadingPage from "./LodingPage";
import RecomModal from "@components/RecomModal";
import MatchModal from "@components/MatchModal";
import ReviewModal from "@components/ReviewModal";
import axios from "axios";
import { RecomResponseType } from "types/DataTypes";
import ShareModal from "@components/ShareModal";
import uxmodal from "@assets/images/ux_modal.png";
import GoogleAd from "@components/GoogleAd";

interface HeightType {
    height: number;
}

const Result = () => {
    const [searchParams] = useSearchParams();
    const user_id = searchParams.get("id");
    const desc = searchParams.get("desc");
    const match = searchParams.get("match");
    const share = searchParams.get("share");

    const [isLoaded, setLoaded] = useState(false);
    const [RecomNum, setRecomNum] = useState(0);
    const [MatchNum, setMatchNum] = useState(0);
    const [recomModalState, setRecomModal] = useState(desc);
    const [matchModalState, setMatchModal] = useState(match);
    const [shareModalState, setShareModal] = useState(share);
    const [thankyouModalState, setThankyouModal] = useState("off");
    const [clickCount, setCount] = useState(0);
    const [userFirstReview, setFirstReview] = useState(1);
    const [userSecondReview, setSecondReview] = useState(1);
    const [userThirdReview, setThirdReview] = useState(1);
    const [resultResponse, setData] = useState<RecomResponseType | null>(null);

    const history = createBrowserHistory();
    const ScrollRef = useRef<HTMLDivElement>(null);

    const userReviewList = [userFirstReview, userSecondReview, userThirdReview];

    const RecomData: RecommendationType | undefined =
        resultResponse?.data.recommendation;
    const RecomHobbies: HobbiesElementType[] | undefined = RecomData?.hobbies;
    const FitHobbyTypes: FitElementType[] | undefined =
        RecomData?.fitHobbyTypes;

    const navigate = useNavigate();
    const AgainHandler = () => {
        navigate("/");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_ENDPOINT +
                        `recommendations/${user_id}`
                );
                setData(response.data);
                // console.log(response.data);
                setTimeout(() => setLoaded(true), 3000);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const removeQueryParams = () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const idParam = urlSearchParams.get("id");

            if (idParam) {
                // console.log("reload");
                const urlWithoutQueryParams =
                    window.location.origin +
                    window.location.pathname +
                    "?id=" +
                    idParam;
                window.history.replaceState(
                    {},
                    document.title,
                    urlWithoutQueryParams
                );
                window.location.reload();
            }
        };
        window.addEventListener("beforeunload", removeQueryParams);
        fetchData();
        return () => {
            window.removeEventListener("beforeunload", removeQueryParams);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const BlockBack = () => {
            navigate("/");
        };
        const unlistenHistoryEvent = history.listen(({ action }) => {
            let cnt = 0;
            if (action === "POP") {
                if (clickCount === 2) {
                    ThankyouModalExitHandler();
                    cnt++;
                }
                if (shareModalState === "on") {
                    ShareModalOff();
                    cnt++;
                }
                if (recomModalState === "on") {
                    RecomModalOff();
                    cnt++;
                }
                if (matchModalState === "on") {
                    MatchModalOff();
                    cnt++;
                }
                if (cnt === 0) {
                    BlockBack();
                }
            }
        });
        return unlistenHistoryEvent;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recomModalState, matchModalState, shareModalState]);

    const RecomModalOff = () => {
        setRecomModal("off");
        history.push(`/result?id=${user_id}`);
        setCount(clickCount + 1);
    };

    const RecomIconHandler = (num: number) => {
        window.scrollTo({ top: 0 });
        history.push(`/result?id=${user_id}&desc=on`);
        setRecomModal("on");
        setRecomNum(num);
        setCount(clickCount + 1);
    };

    const RecomSelectChangeHandler = (num: number) => {
        setRecomNum(num);
    };

    const MatchModalOff = () => {
        setMatchModal("off");
        history.push(`/result?id=${user_id}`);
    };

    const MatchIconHandler = (num: number) => {
        history.push(`/result?id=${user_id}&match=on`);
        setMatchNum(num);
        setMatchModal("on");
    };

    const MatchSelectChangeHandler = (num: number) => {
        setMatchNum(num);
    };

    const ReviewButtonHandler = (choice: number[]) => {
        if (choice[0] === 0) {
            setFirstReview(choice[1]);
        } else if (choice[0] === 1) {
            setSecondReview(choice[1]);
        } else {
            setThirdReview(choice[1]);
        }
    };

    const ReviewJumpHandler = () => {
        const ReviewDataBody = {
            survey: {
                hobbies: [
                    {
                        id: RecomHobbies![0].id,
                        name: RecomHobbies![0].shortName,
                        satisfaction: -1,
                    },
                    {
                        id: RecomHobbies![1].id,
                        name: RecomHobbies![1].shortName,
                        satisfaction: -1,
                    },
                    {
                        id: RecomHobbies![2].id,
                        name: RecomHobbies![2].shortName,
                        satisfaction: -1,
                    },
                ],
            },
        };
        axios({
            method: "post",
            url:
                process.env.REACT_APP_API_ENDPOINT +
                `recommendations/${user_id}/surveys`,
            data: ReviewDataBody,
        });
        setCount(clickCount + 1);
        document.body.style.overflow = "unset";
    };

    const ReviewSubmitHandler = () => {
        const ReviewDataBody = {
            survey: {
                hobbies: [
                    {
                        id: RecomHobbies![0].id,
                        name: RecomHobbies![0].shortName,
                        satisfaction: userReviewList[0] + 1,
                    },
                    {
                        id: RecomHobbies![1].id,
                        name: RecomHobbies![1].shortName,
                        satisfaction: userReviewList[1] + 1,
                    },
                    {
                        id: RecomHobbies![2].id,
                        name: RecomHobbies![2].shortName,
                        satisfaction: userReviewList[2] + 1,
                    },
                ],
            },
        };
        axios({
            method: "post",
            url:
                process.env.REACT_APP_API_ENDPOINT +
                `recommendations/${user_id}/surveys`,
            data: ReviewDataBody,
        })
            .then(() => {
                setThankyouModal("on");
            })
            .catch(() => {});
    };

    const ThankyouModalExitHandler = () => {
        setCount(clickCount + 1);
        document.body.style.overflow = "unset";
        // setSubmitReview(1);
    };

    const ShareButtonPushHandler = () => {
        setShareModal("on");
        history.push(`/result?id=${user_id}&share=on`);
    };

    const ShareModalOff = () => {
        setShareModal("off");
        history.push(`/result?id=${user_id}`);
    };

    if (!RecomData?.didSurvey && clickCount === 2) {
        document.body.style.overflow = "hidden";
    }

    if (!isLoaded) {
        return <LoadingPage stage="recom" />;
    }

    if (recomModalState === "on") {
        return (
            <RecomModal
                RecomHobbies={RecomHobbies!}
                RecomNum={RecomNum}
                RecomModalOff={RecomModalOff}
                RecomSelectChangeHandler={RecomSelectChangeHandler}
            />
        );
    }

    if (matchModalState === "on") {
        return (
            <MatchModal
                FitHobbyTypes={FitHobbyTypes!}
                MatchNum={MatchNum}
                MatchModalOff={MatchModalOff}
                MatchSelectChangeHandler={MatchSelectChangeHandler}
            />
        );
    }

    if (shareModalState === "on") {
        return (
            <ShareModal
                user_id={user_id}
                RecomData={RecomData}
                RecomHobbies={RecomHobbies}
                ShareModalOff={ShareModalOff}
            />
        );
    }

    return (
        <Wrapper>
            {!RecomData?.didSurvey && clickCount === 2 && (
                <ReviewModal
                    ScrollRef={ScrollRef}
                    thankyouModalState={thankyouModalState}
                    ThankyouModalExitHandler={ThankyouModalExitHandler}
                    center_logo={center_logo}
                    RecomHobbies={RecomHobbies!}
                    userReviewList={userReviewList}
                    ReviewButtonHandler={ReviewButtonHandler}
                    ReviewJumpHandler={ReviewJumpHandler}
                    ReviewSubmitHandler={ReviewSubmitHandler}
                />
            )}
            <HeaderDiv>
                <HeaderText>자기개발 리포트</HeaderText>
            </HeaderDiv>
            <GreyDiv>
                <CenterIMG src={RecomData!.hobbyType.imageUrl} alt="" />
                <ResultText>
                    {RecomData!.hobbyType.name}
                    <br />
                    <ResultTextColor>{RecomData?.user.name}</ResultTextColor>님
                    의 추천 자기개발 콘텐츠예요!
                </ResultText>
                <UXModal src={uxmodal} alt="" cnt={clickCount} />
                <RecomDiv>
                    {RecomHobbies!.map((hobby, idx) => (
                        <RecomCard key={idx + 6}>
                            <RecomCircle onClick={() => RecomIconHandler(idx)}>
                                <RecomIMG src={hobby.imageUrl} alt="" />
                            </RecomCircle>
                            <RecomText>{hobby.shortName}</RecomText>
                        </RecomCard>
                    ))}
                </RecomDiv>
                <TypeDescDiv>
                    <TypeDescText>
                        {RecomData!.hobbyType.description}
                    </TypeDescText>
                </TypeDescDiv>
                <RecomDescDiv>
                    <RecomDescTextDiv>
                        {RecomHobbies!.map((hobby, idx) => (
                            <div key={idx + 9}>
                                <RecomDescText>{hobby.summary}</RecomDescText>
                                {/* <br /> */}
                            </div>
                        ))}
                    </RecomDescTextDiv>
                </RecomDescDiv>
            </GreyDiv>
            <UnderGreyText>더 많은 콘텐츠가 궁금하다면</UnderGreyText>
            <HeightBox height={13} />
            <StyledButton
                buttoncolor="green"
                vibrate={0}
                onClick={() => {
                    window.location.href = "https://weeks52.me/";
                }}
            >
                Weeks52 더 알아보기
            </StyledButton>
            <HeightBox height={62} />
            <UnderButtonText>
                <UnderColorText>{RecomData?.user.name}</UnderColorText>님과
                어울리는 자기개발 유형
            </UnderButtonText>
            <RankDiv>
                {FitHobbyTypes!.map((fit, index) => (
                    <RankCard
                        onClick={() => MatchIconHandler(index)}
                        key={index + 12}
                    >
                        <RankIMG src={fit.imageUrl} alt="" />
                        <RankText>{index + 1}위</RankText>
                    </RankCard>
                ))}
            </RankDiv>
            <StyledButton
                buttoncolor="green"
                vibrate={0}
                onClick={ShareButtonPushHandler}
            >
                결과 공유하기
            </StyledButton>
            <HeightBox height={20} />
            <StyledButton buttoncolor="grey" vibrate={0} onClick={AgainHandler}>
                테스트 다시하기
            </StyledButton>
            <HeightBox height={50} />
            <AdWrapper>
                <GoogleAd />
            </AdWrapper>
            <HeightBox height={60} />
        </Wrapper>
    );
};

export default Result;

const Wrapper = styled.div`
    width: 390rem;
    min-height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const HeaderDiv = styled.div`
    height: 50rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46rem;
`;

const HeaderText = styled.span`
    font-family: "Pretendard-Bold";
    font-size: 17rem;
    line-height: 24rem;
    text-align: center;
    color: #000000;
`;

const GreyDiv = styled.div`
    width: 390rem;
    height: 802rem;
    background: #f8f8f8;
    // margin-top: 12rem;
    margin-bottom: 49rem;
`;

const HeightBox = styled.div<HeightType>`
    width: 100%;
    height: ${(props) => props.height}rem;
`;

const UnderGreyText = styled.span`
    margin: 0 auto;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 17rem;
    line-height: 24rem;
    text-align: center;
    color: #000000;
`;

const UnderButtonText = styled.span`
    margin: 0 auto;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 17rem;
    line-height: 24rem;
    text-align: center;
`;

const UnderColorText = styled.span`
    color: #56f46c;
`;

const RankDiv = styled.div`
    margin: 0 auto;
    margin-top: 20rem;
    margin-bottom: 77rem;
    width: 337rem;
    height: 183rem;
    display: flex;
    justify-content: space-between;
`;

const RankCard = styled.div`
    width: 98rem;
    height: 183rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
    border-radius: 10rem;
    align-items: center;
    cursor: pointer;
`;

const RankIMG = styled.img`
    margin: 0 auto;
    margin-top: 32rem;
    margin-bottom: 24rem;
    width: 70rem;
    height: 70rem;
`;

const RankText = styled.div`
    margin: 0 auto;
    width: 25rem;
    height: 25rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 15rem;
    line-height: 25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3e404c;
`;

const CenterIMG = styled.img`
    margin: 0 auto;
    margin-top: 37rem;
    width: 150rem;
    height: 150rem;
    margin-bottom: 17rem;
`;

const ResultText = styled.div`
    margin: 0 auto;
    width: 390rem;

    color: #2f2f2f;
    text-align: center;
    font-family: Pretendard;
    font-size: 14rem;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 22rem; /* 157.143% */
`;

const ResultTextColor = styled.span`
    color: #56f46c;
`;

const TypeDescDiv = styled.div`
    width: 330rem;
    /* height: 104rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    margin: 0 auto;
    margin-top: 16rem;
`;

const TypeDescText = styled.span`
    text-align: left;
    width: 294.507rem;

    color: var(--gray-06, #525463);
    font-family: Pretendard-Medium;
    font-size: 14rem;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 24rem; /* 171.429% */
    white-space: pre-wrap;
    padding: 16rem 10rem 16rem 17rem;
    /* margin-left: 18rem; */
`;

const RecomDiv = styled.div`
    margin: 0 auto;
    margin-top: 17rem;
    width: 295rem;
    /* height: 95rem; */
    display: flex;
    justify-content: space-between;
`;

const RecomCard = styled.div`
    width: 90rem;
    /* height: 95rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RecomIMG = styled.img`
    width: 38rem;
    height: 38rem;
`;

const RecomText = styled.div`
    height: 25rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 13rem;
    line-height: 25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3e404c;
`;

const RecomCircle = styled.div`
    width: 54rem;
    height: 54rem;
    background: #ffffff;
    /* box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.05); */
    border-radius: 40rem;
    margin-bottom: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 4rem solid #49ff66;

    fill: #fff;
    stroke-width: 4rem;
    stroke: #49ff66;
    /* filter: drop-shadow(0px 0px 20rem rgba(0, 0, 0, 0.05)); */
`;

const RecomDescDiv = styled.div`
    margin: 0 auto;
    margin-top: 30rem;
    margin-bottom: 60rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 330rem;
    background: #ffffff;
    border-radius: 10rem;
    padding-left: -20rem;
`;

const RecomDescTextDiv = styled.ul`
    width: 263rem;
    /* height: 191rem; */
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 14rem;
    line-height: 24rem;
    color: #525463;
    text-align: left;
    white-space: pre-wrap;
    padding-left: 0rem;
    margin: -5rem 0;
`;

const RecomDescText = styled.li`
    list-style-position: outside;
    word-break: keep-all;
    margin: 20rem 0;
`;

const ux = keyframes`
0%{
  opacity: 0;
}
25%{
  opacity: 1;
}
75%{
  opacity: 1;
}
100%{
  opacity: 0;
}
`;

interface UXType {
    cnt: number;
}

const UXModal = styled.img<UXType>`
    position: absolute;
    /* margin: 0 auto; */
    width: 265rem;
    height: 35rem;
    flex-shrink: 0;
    transform: translate(-50%, -22rem);
    opacity: 0;
    ${(props) =>
        props.cnt === 0 &&
        css`
            animation: ${ux} 4s 0.5s both;
        `}
`;

const AdWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
`;
