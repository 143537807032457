import styled from "styled-components";
import HeaderLogoBlock from "blocks/HeaderLogoBlock";
import Spinner from "@components/Spinner";

type StageType = {
    stage: string;
};

const LoadingPage = (props: StageType) => {
    return (
        <Wrapper>
            <HeaderLogoBlock />
            <CenterDiv>
                <CenterWrapper>
                    <Spinner />
                    {/* <CenterImg src={loding_img} alt="" /> */}
                    {props.stage === "recom" &&
                        (localStorage.getItem("username") ? (
                            <CenterText>
                                {localStorage.getItem("username")}님의 답변을
                                분석하고 있습니다.
                                <br />
                                조금만 기다려주세요!
                            </CenterText>
                        ) : (
                            <CenterText>
                                답변을 분석하고 있습니다.
                                <br />
                                조금만 기다려주세요!
                            </CenterText>
                        ))}
                    {props.stage === "share" && (
                        <CenterText>
                            컨텐츠 리포트를 불러오고 있어요!
                        </CenterText>
                    )}
                    {props.stage === "rank" && (
                        <CenterText>컨텐츠 목록을 불러오고 있어요!</CenterText>
                    )}
                    {props.stage === "question" && (
                        <CenterText>질문지를 불러오고 있어요!</CenterText>
                    )}
                </CenterWrapper>
            </CenterDiv>
            <FooterDiv />
        </Wrapper>
    );
};

export default LoadingPage;

const Wrapper = styled.div`
    width: 390rem;
    min-height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const CenterDiv = styled.div`
    height: 640rem;
    width: 390rem;
    flex-grow: 640;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CenterWrapper = styled.div`
    postion: absolute;
    margin: auto auto;
    height: 252rem;
    /* width: 180rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const CenterText = styled.div`
    margin-top: 26rem;

    /* width: 250rem; */
    height: 46rem;

    font-family: "Pretendard-Regular";
    font-size: 16rem;
    line-height: 23rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const FooterDiv = styled.div`
    height: 102rem;
    width: 390rem;
    flex-grow: 102;
`;
