import { FitElementType } from "types/DataTypes";
import styled from "styled-components";
import HeaderTextBlock from "blocks/HeaderTextBlock";
import * as C from "@styles/ModalCommonComponent";

type Props = {
    FitHobbyTypes: FitElementType[];
    MatchNum: number;
    MatchModalOff: () => void;
    MatchSelectChangeHandler: (num: number) => void;
};

const MatchModal = (props: Props) => {
    return (
        <C.ResultWrapper>
            <HeaderTextBlock
                title="자기개발 유형 보기"
                Handler={props.MatchModalOff}
            />
            <C.FlexHeightBox height={66.5} />
            <C.RecomModalCenterIMG
                src={props.FitHobbyTypes[props.MatchNum].imageUrl}
                alt=""
            />
            <C.FlexHeightBox height={89.5} />
            <C.RecomSelectDiv>
                {props.FitHobbyTypes.map((fit, idx) => (
                    <SelectBox
                        key={idx}
                        isselected={props.MatchNum === idx ? 1 : 0}
                        onClick={() => props.MatchSelectChangeHandler(idx)}
                    >
                        {idx + 1}위{/* {props.FitHobbyTypes[idx].name} */}
                    </SelectBox>
                ))}
            </C.RecomSelectDiv>
            <RecomSelectDescDiv>
                <TypeText>{props.FitHobbyTypes[props.MatchNum].name}</TypeText>
                <TypeDescDiv>
                    <TypeDescText>
                        {props.FitHobbyTypes[props.MatchNum].description}
                    </TypeDescText>
                </TypeDescDiv>
            </RecomSelectDescDiv>
        </C.ResultWrapper>
    );
};

export default MatchModal;

const TypeDescDiv = styled.div`
    width: 330rem;
    /* height: 104rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    margin: 0 auto;
    margin-top: 16rem;
`;

const TypeText = styled.span`
    display: inline-flex;
    padding: 5rem 15rem;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    height: 25rem;
    margin: 0 auto;
    margin-top: 54rem;

    color: var(--gray-07, #3e404c);
    text-align: center;
    font-family: Pretendard-Medium;
    font-size: 17rem;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 25rem;
`;

const TypeDescText = styled.span`
    text-align: left;
    width: 294.507rem;

    color: var(--gray-06, #525463);
    font-family: Pretendard-Medium;
    font-size: 14rem;
    font-style: normal;
    /* font-weight: 500; */
    line-height: 24rem; /* 171.429% */
    white-space: pre-wrap;
    padding: 16rem 10rem 16rem 17rem;
    /* margin-left: 18rem; */
`;

interface SelectType {
    isselected: number;
}

const SelectBox = styled.div<SelectType>`
    margin-left: 14rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 15rem;
    // height: 32rem;
    font-family: ${(props) =>
        props.isselected === 1 ? "Pretendard-Bold" : "Pretendard-Medium"};
    font-style: normal;
    font-weight: ${(props) => (props.isselected === 1 ? "700" : "500")};
    font-size: 14rem;
    line-height: 25rem;
    ${(props) => props.isselected === 1 && "border-bottom: solid 3rem #98FFA7;"}
    cursor: pointer;
    color: #3e404c;
`;

const RecomSelectDescDiv = styled.div`
    width: 390rem;
    height: 309rem;
    background: #f8f8f8;
`;
