import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import center_logo from "@assets/images/centerlogo.png";
import StyledButton from "@styles/StyledButton";
import logo_img from "@assets/images/start_logo.png";
import { useState, useEffect } from "react";
import axios from "axios";

const Start = () => {
  const navigate = useNavigate();
  localStorage.setItem("username", "");
  const [userCount, setCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_ENDPOINT + "test-responses/count"
        );
        setCount(response.data.data.testResponse.count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Wrapper>
      <TopDiv />
      <LogoImg src={logo_img} alt="" />
      <UnderLogo />
      <Centerdiv>
        <CenterLogo src={center_logo} alt="" />
      </Centerdiv>
      <Descdiv>
        <DescText>
          자기개발하고는 싶은데,
          <br />뭘 해야하는지 모르겠다면?
        </DescText>
      </Descdiv>
      <CountText>지금까지 {userCount}명이 참여했어요!</CountText>
      <Buttondiv>
        <StyledButton
          buttoncolor="green"
          vibrate={0}
          onClick={() => navigate("/register")}
        >
          시작하기
        </StyledButton>
        <SizedBox />
        <StyledButton
          buttoncolor="grey"
          vibrate={0}
          onClick={() => navigate("/ranking")}
        >
          둘러보기
        </StyledButton>
      </Buttondiv>
      <Footerdiv />
    </Wrapper>
  );
};

export default Start;

const Wrapper = styled.div`
  width: 390rem;
  height: 100vh;
  background: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const TopDiv = styled.div`
  height: 77rem;
  width: 390rem;
  //   flex-grow: 78;
`;

const LogoImg = styled.img`
  width: 261.43rem;
  height: 25.5rem;
  margin: 0 auto;
`;

const UnderLogo = styled.div`
  height: 67.5rem;
  width: 100%;
  flex-grow: 67.5;
`;

const CenterLogo = styled.img`
  width: 320rem;
  height: 320rem;
  flex-shrink: 0;
  margin: 0 auto;
`;

const Centerdiv = styled.div`
  width: 100%;
  height: 337rem;
  flex-grow: 337;
  display: flex;
  align-items: center;
`;

const DescText = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size: 15rem;
  line-height: 25rem;
  text-align: center;
  color: #3e404c;
  margin: 0 auto;
`;

const Descdiv = styled.div`
  width: 100%;
  height: 77rem;
  flex-grow: 77;
  display: flex;
  align-items: center;
  margin-bottom: 26.5rem;
`;

const CountText = styled.span`
  height: 25rem;
  color: #3e404c;
  text-align: center;
  font-size: 15rem;
  font-family: Pretendard-Regular;
  line-height: 25rem;
  margin-bottom: 10rem;
`;

const Buttondiv = styled.div`
  width: 100%;
  height: 122rem;
  flex-grow: 122;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Footerdiv = styled.div`
  width: 100%;
  height: 74rem;
  flex-grow: 74;
`;

const SizedBox = styled.div`
  height: 20rem;
  // flex-grow: 20;
`;
