import styled from "styled-components";
// import logo_img from "@assets/images/logo.png";
import logo_img from "@assets/images/start_logo.png";

const HeaderLogoBlock = () => {
  return (
    <>
      <TopDiv />
      <LogoImg src={logo_img} alt="" />
    </>
  );
};

export default HeaderLogoBlock;

const TopDiv = styled.div`
  height: 77rem;
  width: 390rem;
`;

const LogoImg = styled.img`
  width: 261.43rem;
  margin: 0 auto;
`;
