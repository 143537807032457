import React from "react";
import styled from "styled-components";
import backimg from "@assets/images/back.png";

type BlockType = {
  title: string;
  Handler: React.MouseEventHandler<HTMLButtonElement>;
};

const HeaderTextBlock = (props: BlockType) => {
  return (
    <>
      <TopDiv />
      <HeaderDiv>
        <BackButton onClick={props.Handler}>
          <BackImg src={backimg} alt="" />
        </BackButton>
        <HeaderText>{props.title}</HeaderText>
        <HeaderPadding />
      </HeaderDiv>
    </>
  );
};

export default HeaderTextBlock;

const TopDiv = styled.div`
  height: 48rem;
  width: 100%;
  flex-grow: 48;
`;

const HeaderDiv = styled.div`
  height: 50rem;
  width: 100%;
  flex-grow: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackImg = styled.img`
  width: 28.43rem;
  height: 34.11rem;
  cursor: pointer;
`;

const BackButton = styled.button`
  width: 28.43rem;
  height: 34.11rem;
  border: 0px;
  background: white;
`;

const HeaderText = styled.span`
  font-family: "Pretendard-Bold";
  font-size: 17rem;
  line-height: 24rem;
  text-align: center;
  color: #000000;
`;

const HeaderPadding = styled.div`
  width: 28.43rem;
  height: 34.11rem;
  margin-left: 5rem;
`;
