import styled from "styled-components";

interface HeightType {
    height: number;
}

interface SelectType {
    isselected: number;
}

export const ResultWrapper = styled.div`
    width: 390rem;
    height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

export const HeightBox = styled.div<HeightType>`
    width: 100%;
    height: ${(props) => props.height}rem;
`;

export const FlexHeightBox = styled.div<HeightType>`
    width: 100%;
    height: ${(props) => props.height}rem;
    flex-grow: ${(props) => props.height};
`;

export const RecomModalCenterIMG = styled.img`
    margin: 0 auto;
    width: 200rem;
    height: 200rem;
`;

export const RecomSelectDiv = styled.div`
    height: 35rem;
    width: 100%;
    flex-grow: 35;
    display: flex;
`;

export const SelectBox = styled.div<SelectType>`
    margin-left: 14rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 15rem;
    height: 32rem;
    font-family: ${(props) =>
        props.isselected === 1 ? "Pretendard-Bold" : "Pretendard-Medium"};
    font-style: normal;
    font-weight: ${(props) => (props.isselected === 1 ? "700" : "500")};
    font-size: 14rem;
    line-height: 25rem;
    ${(props) => props.isselected === 1 && "border-bottom: solid 3rem #98FFA7;"}
    cursor: pointer;
    color: #3e404c;
`;

export const DescTitle = styled.div`
    margin-left: 16rem;
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 16rem;
    line-height: 24rem;
    text-align: left;
    color: #000000;
`;

export const RecomSelectDescDiv = styled.div`
    width: 390rem;
    height: 388rem;
    flex-grow: 388;
    background: #f8f8f8;
`;

export const DescText = styled.div`
    width: 290rem;
    margin-left: 17rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 14rem;
    line-height: 27rem;
    letter-spacing: -0.03em;
    text-align: left;
    color: #525463;
    white-space: pre-wrap;
    word-break: keep-all;
`;
