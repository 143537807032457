import { HobbiesElementType } from "types/DataTypes";
import styled from "styled-components";
import ReviewCard from "./ReviewCard";

type Props = {
    ScrollRef: React.RefObject<HTMLDivElement>;
    thankyouModalState: string;
    ThankyouModalExitHandler: () => void;
    center_logo: string;
    RecomHobbies: HobbiesElementType[];
    userReviewList: number[];
    ReviewButtonHandler: (choice: number[]) => void;
    ReviewJumpHandler: () => void;
    ReviewSubmitHandler: () => void;
};

const ReviewModal = (props: Props) => {
    return (
        <ReviewBackground ref={props.ScrollRef}>
            {props.thankyouModalState === "on" ? (
                <ThankyouModalWrapper>
                    <ExitButtonDiv>
                        <ThankyouModalExitButton
                            onClick={props.ThankyouModalExitHandler}
                        >
                            X
                        </ThankyouModalExitButton>
                    </ExitButtonDiv>
                    <ThankyouImg src={props.center_logo} alt="" />
                    <ThankyouText>소중한 의견 감사해요!</ThankyouText>
                </ThankyouModalWrapper>
            ) : (
                <ReviewWrapper>
                    <ReviewTopText>추천 받은 컨텐츠가 어땠나요?</ReviewTopText>
                    {props.RecomHobbies.map((hobby, idx) => (
                        <ReviewCard
                            key={idx}
                            hobby={hobby}
                            userReviewList={props.userReviewList}
                            ReviewButtonHandler={props.ReviewButtonHandler}
                            idx={idx}
                        />
                    ))}
                    <ReviewSubmitButtonWrapper>
                        <JumpButton onClick={props.ReviewJumpHandler}>
                            <JumpButtonText>건너뛰기</JumpButtonText>
                        </JumpButton>
                        <SubmitButton onClick={props.ReviewSubmitHandler}>
                            <SubmitButtonText>제출하기</SubmitButtonText>
                        </SubmitButton>
                    </ReviewSubmitButtonWrapper>
                </ReviewWrapper>
            )}
        </ReviewBackground>
    );
};

export default ReviewModal;

const ReviewBackground = styled.div`
    width: 390rem;
    height: 100vh;
    position: absolute;
    top: 0px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const ReviewWrapper = styled.div`
    width: 329.59rem;
    height: 546rem;
    background: #ffffff;
    border-radius: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ReviewTopText = styled.span`
    height: 25.62px;
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 18rem;
    line-height: 25rem;
    color: #444444;
    margin-top: 33.14rem;
    margin-bottom: 24rem;
`;

const ReviewSubmitButtonWrapper = styled.div`
    width: 282.15rem;
    height: 51rem;
    margin-top: 7.24rem;
    display: flex;
`;

const JumpButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 105rem;
    height: 51rem;

    background: #f1f1f1;
    border-radius: 25rem;
    margin-right: 12.15rem;
    cursor: pointer;
`;

const SubmitButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 165rem;
    height: 51rem;

    background: #f1f1f1;
    &:hover {
        background: #98ffa7;
    }
    border-radius: 25rem;
    cursor: pointer;
`;

const JumpButtonText = styled.span`
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 16rem;
    line-height: 19rem;
    color: #7d7f8a;
`;

const SubmitButtonText = styled.span`
    color: #353637;
    text-align: center;
    font-size: 16rem;
    font-family: Pretendard-SemiBold;
    /* font-weight: 600; */
`;

const ThankyouModalWrapper = styled.div`
    width: 329.59rem;
    height: 250rem;
    background: #ffffff;
    border-radius: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ThankyouImg = styled.img`
    width: 140rem;
    height: 140rem;
    margin-top: 2rem;
    margin-bottom: 11.12rem;
`;

const ThankyouText = styled.span`
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 15rem;
    line-height: 15rem;
`;

const ExitButtonDiv = styled.div`
    width: 93%;
    height: 30rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const ThankyouModalExitButton = styled.button`
    border: 0px;
    background: transparent;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 15rem;
    line-height: 15rem;
    cursor: pointer;
`;
