import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Start from "pages/StartPage";
import Register from "pages/RegisterPage";
import Question from "pages/QuestionPage";
import Result from "pages/ResultPage";
import { RecoilRoot } from "recoil";
import SharePage from "pages/SharePage";
import RankingPage from "pages/RankingPage";

declare global {
  interface Window {
    Kakao: any;
  }
}

function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Start />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/question" element={<Question />}></Route>
            <Route path="/result" element={<Result />}></Route>
            <Route path="/share" element={<SharePage />}></Route>
            <Route path="/ranking" element={<RankingPage />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </RecoilRoot>
  );
}

export default App;
