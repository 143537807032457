import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { ShareResponseType } from "types/DataTypes";
import { useSearchParams } from "react-router-dom";
import StyledButton from "@styles/StyledButton";
import { useNavigate } from "react-router-dom";
import LoadingPage from "./LodingPage";

const SharePage = () => {
    const [searchParams] = useSearchParams();
    const user_id = searchParams.get("id");
    const [isLoaded, setLoaded] = useState(false);
    const [response, setData] = useState<ShareResponseType | null>(null);
    const RecomData = response?.data.recommendation;
    const RecomHobbies = RecomData?.hobbies;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_ENDPOINT +
                        `recommendations/${user_id}/shares`
                );
                setData(response.data);
                setLoaded(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    const AgainHandler = () => {
        navigate("/");
    };

    if (!isLoaded) {
        return <LoadingPage stage="share" />;
    }

    return (
        <ShareWrapper>
            <ShareHeaderDiv>
                <HeaderTextDiv>
                    {RecomData?.user.name}님의 자기개발 리포트
                </HeaderTextDiv>
            </ShareHeaderDiv>
            <ShareGreyDiv id="capture_div">
                <ShareCenterWrapper>
                    <ShareCenterIMG
                        src={RecomData!.hobbyType.imageUrl}
                        alt=""
                    />
                    <ResultText>
                        {RecomData!.hobbyType.name}의 추천 컨텐츠예요!
                    </ResultText>
                    <ShareRecomDiv>
                        {RecomHobbies!.map((hobby, idx) => (
                            <RecomCard key={idx}>
                                <RecomCircle>
                                    <RecomIMG src={hobby.imageUrl} alt="" />
                                </RecomCircle>
                                <RecomText>{hobby.shortName}</RecomText>
                            </RecomCard>
                        ))}
                    </ShareRecomDiv>
                    <ShareRecomDescDiv>
                        <RecomDescTextDiv>
                            {RecomHobbies!.map((hobby, idx) => (
                                <div key={idx + 3}>
                                    <RecomDescText>
                                        {hobby.summary}
                                    </RecomDescText>
                                    <br />
                                </div>
                            ))}
                        </RecomDescTextDiv>
                    </ShareRecomDescDiv>
                </ShareCenterWrapper>
            </ShareGreyDiv>
            <ShareButtonsDiv>
                <StyledButton
                    buttoncolor="green"
                    vibrate={0}
                    onClick={AgainHandler}
                >
                    나도 테스트해보기
                </StyledButton>
            </ShareButtonsDiv>
        </ShareWrapper>
    );
};

export default SharePage;

const ShareHeaderDiv = styled.div`
    height: 11.374vh;
    width: 390rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const HeaderTextDiv = styled.div`
    width: 390rem;
    height: 50rem;
    font-family: "Pretendard-Bold";
    font-style: normal;
    /* font-weight: 700; */
    font-size: 17rem;
    line-height: 20rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #000000;
`;

const ShareGreyDiv = styled.div`
    width: 390rem;
    // height: 75.592vh;
    height: 80vh;
    // height: 638rem;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ShareButtonsDiv = styled.div`
    width: 337rem;
    padding-left: 26.5rem;
    padding-right: 26.5rem;
    // height: 13.033vh;
    height: 8.625vh;
    display: flex;
    margin: 0 auto;
    background: #f8f8f8;
`;

const ShareCenterWrapper = styled.div`
  display: flex
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShareCenterIMG = styled.img`
    margin: 0 auto;
    // margin-top: 26rem;
    width: 150rem;
    height: 150rem;
    margin-bottom: 13.5rem;
`;

const ShareRecomDiv = styled.div`
    margin: 0 auto;
    margin-top: 13.5rem;
    margin-bottom: 12rem;
    width: 266rem;
    /* height: 95rem; */
    display: flex;
    justify-content: space-between;
`;

const ShareRecomDescDiv = styled.div`
    margin: 0 auto;
    // margin-bottom: 27rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 330rem;
    height: 225rem;
    background: #ffffff;
    border-radius: 10rem;
`;

const ShareWrapper = styled.div`
    width: 390rem;
    height: 100vh;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.1);
`;

const ResultText = styled.div`
    margin: 0 auto;
    width: 390rem;
    height: 30rem;
    font-family: "Pretendard-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 17rem;
    line-height: 30rem;
`;

const RecomCard = styled.div`
    min-width: 62rem;
    /* height: 95rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RecomIMG = styled.img`
    width: 40rem;
    height: 40rem;
`;

const RecomText = styled.div`
    height: 25rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 13rem;
    line-height: 25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3e404c;
`;

const RecomCircle = styled.div`
    width: 59rem;
    height: 59rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 20rem rgba(0, 0, 0, 0.05);
    border-radius: 31rem;
    margin-bottom: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1.5rem solid #49ff66;
    // cursor: pointer;
`;

const RecomDescTextDiv = styled.ul`
    width: 263rem;
    height: 191rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 14rem;
    line-height: 24rem;
    color: #525463;
    text-align: left;
    white-space: pre-wrap;
    padding-left: 0rem;
`;

const RecomDescText = styled.li`
    list-style-position: outside;
    word-break: keep-all;
`;
