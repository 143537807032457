import { HobbiesElementType } from "types/DataTypes";
import styled from "styled-components";

type Props = {
    hobby: HobbiesElementType;
    userReviewList: number[];
    ReviewButtonHandler: (choice: number[]) => void;
    idx: number;
};

const ReviewCard = (props: Props) => {
    return (
        <ReviewCardWrapper>
            <CardImgTextDiv>
                <CardImg src={props.hobby.imageUrl} alt="" />
                <CardText>{props.hobby.shortName}</CardText>
            </CardImgTextDiv>
            <ReviewButtonDiv>
                <ReviewButton
                    isselected={props.userReviewList[props.idx] === 0 ? 1 : 0}
                    onClick={() => props.ReviewButtonHandler([props.idx, 0])}
                >
                    <ReviewButtonText>별로에요</ReviewButtonText>
                </ReviewButton>
                <ReviewButton
                    isselected={props.userReviewList[props.idx] === 1 ? 1 : 0}
                    onClick={() => props.ReviewButtonHandler([props.idx, 1])}
                >
                    <ReviewButtonText>괜찮네요</ReviewButtonText>
                </ReviewButton>
                <ReviewButton
                    isselected={props.userReviewList[props.idx] === 2 ? 1 : 0}
                    onClick={() => props.ReviewButtonHandler([props.idx, 2])}
                >
                    <ReviewButtonText>좋아요</ReviewButtonText>
                </ReviewButton>
            </ReviewButtonDiv>
        </ReviewCardWrapper>
    );
};

export default ReviewCard;

const ReviewCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 282.15rem;
    height: 109rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 10rem rgba(0, 0, 0, 0.15);
    border-radius: 10rem;
    margin-bottom: 14rem;
`;

const CardImgTextDiv = styled.div`
    margin-left: 20rem;
    margin-top: 10rem;
    margin-bottom: 8rem;
    height: 50rem;
    display: flex;
    align-items: center;
`;

const CardImg = styled.img`
    width: 50rem;
    height: 50rem;
`;

const CardText = styled.span`
    margin-left: 15rem;
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 15rem;
    line-height: 25rem;
    color: #444444;
`;

interface ReviewButtonType {
    isselected: number;
}

const ReviewButtonDiv = styled.div`
    width: 225rem;
    height: 31rem;
    display: flex;
    margin-left: 20rem;
`;

const ReviewButton = styled.button<ReviewButtonType>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 31rem;
    padding: 3rem 10rem;
    gap: 10rem;
    background: ${(props) => (props.isselected === 1 ? "#98FFA7" : "#f1f1f1")};
    border-radius: 20rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 0px;
    margin-right: 11rem;
    cursor: pointer;
`;

const ReviewButtonText = styled.span`
    font-family: "Pretendard-Medium";
    font-style: normal;
    /* font-weight: 500; */
    font-size: 15rem;
    line-height: 25rem;
    color: #444444;
`;
